import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApilistService } from 'src/app/services/api/apilist.service';
import { MessageService } from 'src/app/services/messageHandling/message.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPwdForm: FormGroup;
  constructor(private api: ApilistService, private router: Router, public message: MessageService) {
    this.forgotPwdForm = new FormGroup({
      email: new FormControl('', [Validators.required, this.emailValidator()]),
    })
  }

  ngOnInit(): void {
  }


  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!control.value || !emailRegex.test(control.value)) {
        return { invalidEmail: true };
      }

      return null;
    };
  }

  submitData() {
    if(this.forgotPwdForm.value.email){
    let data={
      "email":this.forgotPwdForm.value.email
    }
    this.api.forgotPassword(data).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.message.successMessage(res.message, '')
      }
      else {
        this.message.errorMessage(res.message, '',2000)
      }
    })
    }
    else{
      this.message.errorMessage('Please enter email','',2000)
    }
  }
}

