
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from '../messageHandling/message.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public message:MessageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
       
        switch (error.error.statusCode) {
          case 400:
            /********** handle error msg **********/
            this.message.errorMessage(error.error.message, 'OK',60000)
            break;
          // case 0:
          //   /********** If server dosent respond **********/
          //   this.message.errorMessage(error.error.error, 2000)
          //   break;
          default:
            /********** Check for other serve-side errors **********/
            this.message.errorMessage(error.error.message, 'OK',6000)
            break;
        }
      }

    }));
 
}

}

