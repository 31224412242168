import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private snackBar: MatSnackBar,) { }



  successMessage(message :any, action:any){
    this.snackBar.open(message, action, {
     duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center" 
    });
  }

  errorMessage(message: any, action: any,duration:any) {
    const snackBarRef = this.snackBar.open(message, action, {
       duration: duration, 
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });

    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss(); 
    });
  }
  // errorMessage(message :any, action:any){
  //   this.snackBar.open(message, action, {
  //    duration: 7000,
  //     verticalPosition: "top", 
  //     horizontalPosition: "center" 
  //   });
  // }
}
